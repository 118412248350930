import React from "react";
import ContentAfbeeldingTekst from "./ContentAfbeeldingTekst"
import ProjectImageLarge from "./ProjectImageLarge"
import ProjectImageSmallLarge from "./ProjectImageSmallLarge"

function ContentBlocksProject({blocks}) {
  let iteratorSmallLarge = 1;
  const contentBlocks = (blocks || [])
    .map((c, i) => {
      let el = null;
      switch (c._type) {
        case "contentAfbeeldingTekst":
          iteratorSmallLarge++
          el = <ContentAfbeeldingTekst {...c} iterator={iteratorSmallLarge} key={i} />;
          break;
        case "projectImageLarge":
          el = <ProjectImageLarge {...c} key={i} />;
          break;
        case "projectImageSmallLarge":
          iteratorSmallLarge++
          el = <ProjectImageSmallLarge {...c} iterator={iteratorSmallLarge} key={i} />;
          break;
        default:
          el = "";
      }
      return el;
    });

  return (
    <div>
      {contentBlocks}
    </div>
  )
}

export default ContentBlocksProject