import React from "react";
import Img from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "/client-config";

function ProjectImageSmallLarge(props) {
  const fluidPropsSmall = props.imageSmall && getFluidGatsbyImage(props.imageSmall.asset, {maxWidth: 500}, clientConfig.sanity)
  const fluidPropsLarge = props.imageLarge && getFluidGatsbyImage(props.imageLarge.asset, {maxWidth: 1000}, clientConfig.sanity)
  return (
    <div key={props._id} className={props.iterator%2 === 1 ? 'block row order-even' : 'block row order-odd'}>
      <div className="small-12 medium-8 columns content-img">
        {props.imageLarge &&
          <Img className="portfolio__image" fluid={fluidPropsLarge} alt={props.title} />
        }
      </div>
      <div className="small-12 medium-4 columns content-img">
        {props.imageSmall &&
          <Img className="portfolio__image" fluid={fluidPropsSmall} alt={props.title} />
        }
      </div>
    </div>
  )
}

export default ProjectImageSmallLarge

