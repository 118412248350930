import React from "react"
import { Link, graphql } from "gatsby"
import * as PropTypes from "prop-types"
import Helmet from "react-helmet";
import Layout from "../../layouts"
import Video from "../Video";
import BlockContent from "@sanity/block-content-to-react"
import ContentBlocksProject from '../Content/BlocksProject';
import ContentBlocks from "../Content/Blocks";

const propTypes = {
  data: PropTypes.object.isRequired,
}

class ProjectTemplate extends React.Component {
  render() {
    const project = this.props.data.project
    return (
      <Layout>
        <article>
          <Helmet>
            <title>{project.title}</title>
            <meta name="description" content={project.metaDescription} />
          </Helmet>
          <div  className="panel">
            <div className="row v-align-center content-text">
              <div className="medium-2 columns">
                <Link to="/projecten/">&#8592; Overzicht</Link>
              </div>
              <div className="medium-10 columns">
                <h1 className="portfolio__title">{project.title}</h1>
              </div>
            </div>
            {project._rawDescription &&
              <div className="portfolio__description content-text">
                <BlockContent blocks={project._rawDescription} />
              </div>
            }
          </div>
          <div className="panel panel--xlarge">
            {project.video &&
              <div className="block content-img">
                <Video videoUrl={project.video} />
              </div>
            }
          </div>
          {(project._rawContentImages && project._rawContentImages.length > 1) ?
            <div className="panel panel--xlarge" key="1">
              {project._rawContentImages &&
                <ContentBlocks blocks={project._rawContentImages} />
              }
            </div>
          :
            <div className="panel panel--xlarge" key="2">
              {project._rawProjectImages &&
                <ContentBlocksProject blocks={project._rawProjectImages} />
              }
            </div>
          }
        </article>
      </Layout>
    )
  }
}

ProjectTemplate.propTypes = propTypes

export default ProjectTemplate

export const pageQuery = graphql`
  query($id: String!) {
    project: sanityProject(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      type
      metaDescription
      video
      _rawDescription
      _rawContentImages(resolveReferences: {maxDepth: 5})
      _rawProjectImages(resolveReferences: {maxDepth: 5})
    }
  }
`