/* eslint-disable */
import React from "react";

export default class YoutubeVideo extends React.Component {
  render() {
    function extractYoutubeVideoID(url){
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      var match = url.match(regExp);
      if ( match && match[7].length === 11 ){
        return match[7];
      }
    }

    var videoId = extractYoutubeVideoID(this.props.videoUrl);
    return (
      <div className='video-container' itemProp='video'>
        <iframe width='560' height='315' src={`//www.youtube-nocookie.com/embed/${videoId}?html5=1&autoplay=0&controls=0&modestbranding=1&rel=0&showinfo=0&autohide=1&color=white&iv_load_policy=3&theme=light&playsinline=1`} frameBorder='0' allowFullScreen></iframe>
      </div>
    )
  }
}