import React from "react";
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "/client-config";

function ContentAfbeeldingTekst(props) {
  const fluidProps = props.image && getFluidGatsbyImage(props.image.asset, {maxWidth: 1000}, clientConfig.sanity)
  const iterator = props.iterator
  return (
    <div key={props._id} className={iterator%2 === 1 ? 'block row order-even' : 'block row order-odd'}>
      <div className="small-12 medium-8 columns content-img">
          {props.image &&
            <Img className="portfolio__image" fluid={fluidProps} alt={props.title} />
          }
      </div>
      <div className="small-12 medium-4 columns content-text">
          {props.title &&
            <h2>{props.title}</h2>
          }
          {props.text &&
            <BlockContent blocks={props.text} />
          }
      </div>
    </div>
  )
}

export default ContentAfbeeldingTekst

